import React, { PureComponent } from 'react';
import Loadable from 'react-loadable';
import { MFAProvider } from './MFAContext';

const MFAComponent = Loadable({
  loader: () => import(/* webpackChunkName: 'your-mfa-modal' */ './MFAComponent'),
  loading: () => false,
});

class MFAModalContainer extends PureComponent {
  render() {
    return (
      <MFAProvider>
        <MFAComponent />
      </MFAProvider>
    );
  }
}

export default MFAModalContainer;
