import React from 'react';
import './DTLoginHeader.scss';
import window from 'window-or-global';
import { connect } from 'react-redux';
import horchowLogo from 'hcAssets/images/horchow.png';
import fullWidthNMLogo from '../../../assets/images/neiman-marcus-logo-full-width.svg';
import leftArrowBoldBlack from '../../../assets/images/left_arrow_bold_black.svg';

function DTLoginHeader(props) {
  const goBackButton = () => window.history.back();
  return (
    <div className="login-header-wrapper">

      <div className="logo-header-wrapper-right">
        <img
          onClick={goBackButton}
          src={String(leftArrowBoldBlack)}
          className="login-arrow"
          aria-label="Go Back"
          role="button"
          alt="left-arrow"
        />
      </div>

      {props.brand && props.brand === 'HC' ? (
        <div className="logo-header-wrapper">
          <a className="logo-header-wrapper-link" href="/">
            <img
              className="login-header-image-for-horchow"
              src={horchowLogo}
              alt="Horchow Logo"
            />
          </a>
        </div>
      ) : (
        <div className="logo-header-wrapper">
          <a className="logo-header-wrapper-link" href="/">
            <img
              className="login-header-image"
              src={String(fullWidthNMLogo)}
              alt="Neiman Marcus"
            />
          </a>
        </div>
      )}

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    brand: state.brand_name?.env,
  };
};
export default connect(mapStateToProps)(DTLoginHeader);
