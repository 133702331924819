import React, { Component, createContext } from 'react';
import Amplify from '@aws-amplify/core';
import { configureAmplifyEnv } from '@nmg/auth';
import { getMFAConfig } from '../YourNeimans/components/utilities';
import {

  getEnv,

} from '../../../utilities/amplifyUtils';
import { getBasicProfileInfo, checkIfMFAOptInAvailable } from '../../../utilities/mfaActionUtils';

const Context = createContext();
const { Consumer } = Context;

class MFAProvider extends Component {
    state = {
      isOpen: false,
      toggleMFAModal: (resetStep, mfaContext) => this.toggleMFAModal(resetStep, mfaContext),
      userAttributes: {},
      isFetchingUserAttributes: false,
      mfaContext: {
        flow: 'Optin', // Signin
        postLogin: (user) => user,
        mfaUser: undefined,
        toggles: {},
      },
      closeModal: () => this.closeModal(),
    }

    componentDidMount() {
      const { toggles, location } = getMFAConfig();
      if (this.props.configureAmplify) {
        const envObj = getEnv();

        toggles?.USE_AUTH_CONFIG
          ? Amplify.configure(configureAmplifyEnv(toggles))
          : this.props.configureAmplify(envObj, 'NM', toggles?.USE_CONFIDENTIAL_CLIENT);
      }
      this.setState(({ isFetchingUserAttributes: true }));
      getBasicProfileInfo(this.setBasicProfileData);
      this.setState({
        toggles,
        location,
      });
      window.YourNeimansMFA = {
        ...window.YourNeimansMFA,
        toggleMFA: (resetStep, mfaContext) => this.toggleMFAModal(resetStep, mfaContext),
        canOptin: checkIfMFAOptInAvailable,
        refreshBasicProfile: () => getBasicProfileInfo(this.setBasicProfileData),
      };
      window.sessionStorage.setItem('mfa_configured', 'true');
    }

    toggleMFAModal = (resetStep = () => {}, mfaContext = { flow: 'Optin' }) => {
      const user = mfaContext.mfaUser;
      if (user) {
        // If user attributes are missed, it can be only sign in flow
        let email = null;
        if (user?.extra) {
          email = user?.extra?.email;
        }
        if (!email) {
          const emailRegExp = new RegExp(`${user?.keyPrefix || ' '}.|.userData`, 'g');
          email = user?.userDataKey && user?.keyPrefix ? user.userDataKey?.replace(emailRegExp, '') : '';
        }
        const smsSentPhone = user?.challengeParam?.CODE_DELIVERY_DESTINATION || '';

        this.setBasicProfileData({
          ...this.state.userAttributes,
          phone_number: smsSentPhone,
          email,
        });
      }
      this.state.isOpen && resetStep();
      this.setState((state) => ({ isOpen: !state.isOpen, mfaContext }));
    };

    closeModal= () => this.setState({ isOpen: false })

    dispatch = () => ({
      toggleMFAModal: () => {
        this.setState((state) => ({ isOpen: !state.isOpen }));
      },
      closeModal: () => this.setState({ isOpen: false }),
    });

    setBasicProfileData = (userAttributes = {}) => {
      this.setState({
        userAttributes,
        isFetchingUserAttributes: false,
      });
    };

    updateUserAttributes = (userAttributes) => {
      this.setState({ userAttributes: { ...userAttributes } });
    };

    render() {
      const { children } = this.props;
      return (
        <Context.Provider
          value={{ ...this.state, updateUserAttributes: this.updateUserAttributes }}
        >
          {children}
        </Context.Provider>
      );
    }
}

export { MFAProvider, Consumer, Context };
