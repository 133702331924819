import React, { Component } from 'react';
import { fpFraudDetect } from '@nmg/auth';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Auth from '@aws-amplify/auth';
import Modal from 'shared/components/Modal/modal';
import {
  domLoadCompleted,
  setPageId,
  setPageVisited,
  PAGE_ID_LOGIN,
} from 'shared/actions/actions-page';
import {
  setMfaUser,
  openForgotPasswordModal,
  sendLoginPagesUtagDataOnPageload,
  loginFailureUtagData,
} from 'client/profile/actions/actions';
import { addEventListener, removeEventListener } from 'window-or-global';
import './AccountAccess.scss';
import { getParams } from 'client-utils/utilities-router';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import { updateUtagData } from 'client/common/components/YourNeimans/components/utilities';
import { getSummaryData } from 'client/utilities/loayltyUtils';
import AccountAccess from './AccountAccess';
import LetsConfirm from './LetsConfirm';
import DTLoginHeader from '../DTLoginHeader/DTLoginHeader';
import {
  entryValidation,
  amplifyLogin,
  loginErrorCallBack,
} from '../../../utilities/amplifyUtils';
import { getNMVIPCheckSpendAmount, getBillingAddressPrefill } from './utilities';

export class DumbAccountAccessWrapper extends Component {
  constructor(props) {
    super(props);
    const { setPageId, setPageVisited } = this.props;
    const { customer } = getParams(this.props.location);
    this.state = {
      email: customer || '',
      password: '',
      displayError: false,
      loading: false,
      errorMessage: '',
      checked: true,
      user: this.props.mfaUser,
      mfaOtp: this.props?.params?.optParam === 'otp',
      verfiy: this.props?.params?.optParam === 'verify',
    };
    setPageId(PAGE_ID_LOGIN);
    setPageVisited({
      event_name: 'pageLoad',
      page_definition_id: 'account',
      page_name: 'login',
      page_template: 'account',
      page_type: 'account',
      site_abbreviation: 'nm',
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.mfaUser && props.mfaUser !== state.user) {
      return { ...state, ...{ user: props.mfaUser } };
    }
    return null;
  }

  componentDidMount() {
    const {
      isDomLoadComplete,
      sendLoginPagesUtagDataOnPageload,
    } = this.props;
    addEventListener('load', this.handleLoad);
    if (isDomLoadComplete) {
      sendLoginPagesUtagDataOnPageload();
    }
    if (this.props.brand === 'HC') {
      window.location.href = '/account/login/';
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isDomLoadComplete !== prevProps.isDomLoadComplete
    ) {
      if (
        this.props.isDomLoadComplete
       && this.props.VALIDATE_LOGIN
      ) {
        fpFraudDetect('lg');
      }
    }
  }

         handleLoad = () => {
           this.props.domLoadCompleted();
           removeEventListener('load', this.handleLoad);
         };

         openModal = () => {
           const email = this.state.email;
           this.props.openForgotPasswordModal('forgot-password-modal', {
             email,
           });
         };

         handleSubmit = (e) => {
           e.preventDefault();
           const { email, password, checked } = this.state;
           if (!entryValidation(email, password)) {
             this.props.loginFailureUtagData(checked);
             this.setState({
               displayError: true,
               errorMessage:
                 'The supplied email address or password is incorrect.',
             });
           } else {
             checked && saveToLocalStorage('_kmsi', true);
             this.userLogin(email, password, checked);
           }
         };

         userLogin = (email, password, checked) => {
           const {
             DT_LOGIN_FLOW,
             UCA_PROFILE_COOKIE,
             PZP_IDENTITY,
             DISABLE_ATG_LOGIN,
             UCA_MFA,
             EMPLOYEE_STATUS_V2,
             DISCOUNT_ELIGIBILITY_V1,
             state,
             NMO_LOYALTY_NEW,
           } = this.props;

           const errorCallBack = (error) => {
             const { LOCK_AUTHSTATUS_API } = this.props;
             this.props.loginFailureUtagData(checked);
             loginErrorCallBack(error, this, email, LOCK_AUTHSTATUS_API);
           };
           const successCallBack = async (user) => {
             if (NMO_LOYALTY_NEW) {
               try {
                 await getSummaryData(user, updateUtagData);
               } catch (error) {
                 console.log(error);
               }
             }

             if (UCA_MFA && user.challengeName === 'SMS_MFA') return;
             const { navpath } = getParams(this.props.location);

             if (navpath === 'loyalty') {
               window.location.href = '/my/NMCreditCard';
             } else if (navpath === 'apply') {
               getBillingAddressPrefill(state);
             } else if (navpath === 'stylePref') {
               window.location.href = '/my/preferences/stylepreferences';
             } else if (navpath === 'specialOffers') {
               window.location.href = '/specialoffers';
             } else if (navpath === 'nmvip') {
               getNMVIPCheckSpendAmount().then((data) => {
                 if (data === 0) {
                   window.location.href = '/my/account/overview';
                 } else if (data?.nmVipQuizEligible) {
                   window.location.href = data?.nmVipMatchRelation
                     ? '/my/account/overview'
                     : '/quiz/nmvip';
                 } else {
                   window.location.href = (data?.saMatchRelation || data?.nmVipMatchRelation)
                     ? '/my/account/overview'
                     : '/editorial/nmvip-interest';
                 }
               });
             } else if (this.props.ACCOUNT_OVERVIEW_DT) {
               window.sessionStorage.setItem(
                 'dt_success_login',
                 'true'
               ); //  Account page takes this variable for utag tracking
               window.location.href = '/my/account/overview';
             } else window.location.href = '/account/account.jsp';
           };
           const goToMfa = (user) => {
             this.props.setMfaUser(user);
             if (UCA_MFA) {
               this.setState({ user, mfaOtp: true });
               this.props.router.push('/login/otp?redirectTo=/my/account/overview');
             }
           };

           this.setState({ loading: true });
           amplifyLogin(
             email,
             password,
             errorCallBack,
             false,
             successCallBack,
             DT_LOGIN_FLOW,
             UCA_PROFILE_COOKIE,
             PZP_IDENTITY,
             DISABLE_ATG_LOGIN,
             UCA_MFA,
             goToMfa,
             EMPLOYEE_STATUS_V2,
             DISCOUNT_ELIGIBILITY_V1,
           );
         };

         toggleCheck = () => {
           this.setState((state) => ({ checked: !state.checked }));
         };

         handleChange = (e) => {
           this.setState({
             [e.target.name]: e.target.value,
           });
         };

         UNSAFE_componentWillMount() {
           if (IS_CLIENT) {
             Auth.currentAuthenticatedUser({})
               .then((user) => {
                 const currentSession = user.signInUserSession;
                 user.refreshSession(currentSession.refreshToken, () => {
                   const { navpath } = getParams(this.props.location);

                   if (navpath === 'nmvip') {
                     getNMVIPCheckSpendAmount().then((data) => {
                       if (data === 0) {
                         window.location.href = '/my/account/overview';
                       } else if (data?.nmVipQuizEligible) {
                         window.location.href = data?.nmVipMatchRelation
                           ? '/my/account/overview'
                           : '/quiz/nmvip';
                       } else {
                         window.location.href = (data?.saMatchRelation || data?.nmVipMatchRelation)
                           ? '/my/account/overview'
                           : '/editorial/nmvip-interest';
                       }
                     });
                   } else {
                     window.location.href = '/account/account.jsp';
                   }
                 });
               })
               .catch((e) => e);
           }
         }


         render() {
           const {
             displayError,
             loading,
             errorMessage,
             checked,
           } = this.state;
           const { navpath, redirectTo } = getParams(this.props.location);
           return (
             <div className="account-page-wrapper">

               <DTLoginHeader />
               {this.props.brand === 'HC' ? 'Page not found' : (
                 <>
                   <Modal />
                   {
                !this.state.mfaOtp
                  ? (
                    <AccountAccess
                      handleSubmit={this.handleSubmit}
                      handleChange={this.handleChange}
                      displayError={displayError}
                      loading={loading}
                      errorMessage={errorMessage}
                      checked={checked}
                      toggleCheck={this.toggleCheck}
                      showForgotPwModal={this.openModal}
                      brand={this.props.brand}
                      navpath={navpath}
                      {...this.state}
                      {...this.props}
                    />
                  )
                  : (
                    <LetsConfirm
                      toggles={{
                        UCA_MFA: this.props.UCA_MFA,
                        PZP_IDENTITY: this.props.PZP_IDENTITY,
                        UCA_PROFILE_COOKIE: this.props.UCA_PROFILE_COOKIE,
                        DISABLE_ATG_LOGIN: this.props.DISABLE_ATG_LOGIN,
                        EMPLOYEE_STATUS_V2: this.props.EMPLOYEE_STATUS_V2,
                        DISCOUNT_ELIGIBILITY_V1: this.props.DISCOUNT_ELIGIBILITY_V1,
                      }}
                      user={this.state.user}
                      redirect={redirectTo}
                      isVerify={this.state.verfiy}
                      {...this.props}
                    />
                  )}
                 </>
               )}
             </div>
           );
         }
}
const mapDispatchToProps = {
  openForgotPasswordModal,
  sendLoginPagesUtagDataOnPageload,
  domLoadCompleted,
  setPageId,
  setPageVisited,
  loginFailureUtagData,
  setMfaUser,
};

const mapStateToProps = (state) => {
  const DT_LOGIN_FLOW = get(state, 'toggles.DT_LOGIN_FLOW', false);
  const isDomLoadComplete = get(state, 'page.isDomLoadComplete', false);
  const ACCOUNT_OVERVIEW_DT = get(state, 'toggles.ACCOUNT_OVERVIEW_DT', false);
  const UCA_PROFILE_COOKIE = get(state, 'toggles.UCA_PROFILE_COOKIE', false);
  const LOCK_AUTHSTATUS_API = get(state, 'toggles.LOCK_AUTHSTATUS_API', false);
  const PZP_IDENTITY = get(state, 'toggles.PZP_IDENTITY', false);
  const DISABLE_ATG_LOGIN = get(state, 'toggles.DISABLE_ATG_LOGIN', false);
  const UCA_MFA = get(state, 'toggles.UCA_MFA', false);
  const VALIDATE_LOGIN = get(state, 'toggles.VALIDATE_LOGIN', false);
  const EMPLOYEE_STATUS_V2 = get(state, 'toggles.EMPLOYEE_STATUS_V2', false);
  const DISCOUNT_ELIGIBILITY_V1 = get(state, 'toggles.DISCOUNT_ELIGIBILITY_V1', false);
  const NMO_LOYALTY_NEW = get(state, 'toggles.NMO_LOYALTY_NEW', false);
  const LOY_LANDING = get(state, 'toggles.LOY_LANDING', false);
  return {
    DT_LOGIN_FLOW,
    isDomLoadComplete,
    brand: state.brand_name?.env,
    ACCOUNT_OVERVIEW_DT,
    UCA_PROFILE_COOKIE,
    LOCK_AUTHSTATUS_API,
    PZP_IDENTITY,
    DISABLE_ATG_LOGIN,
    UCA_MFA,
    VALIDATE_LOGIN,
    EMPLOYEE_STATUS_V2,
    DISCOUNT_ELIGIBILITY_V1,
    mfaUser: state.profile.dtLogin.mfauser,
    NMO_LOYALTY_NEW,
    LOY_LANDING,
    state,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DumbAccountAccessWrapper);
