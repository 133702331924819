/* istanbul ignore file */
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import {
  getTime, isAfter, isBefore, addDays,
} from 'date-fns';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import {
  getAuthApiData,
  obtainAtgSession,
  getFastlyHost,
  getUcaId,
} from './amplifyUtils';
import {
  setUcaProfileCookie,
  knownCustomerCookie,
} from '../common/components/YourNeimans/components/utilities';

const LOCAL_STORAGE_MFA_OPT_IN_REFUSES = 'MFA_OPT_IN_REFUSES';

/**
 * Utility function to send logs
 * @param {*} data
 * @param {*} origin
 * @param {*} level
 */
const sendLog = (data = {}, origin = 'MFA', level = 'info') => {
  const getLambdaHost = () => window.sessionStorage.getItem('lambdaHost');
  let body = {
    data: {
      group: origin,
      ...data,
    },
    level,
  };
  const { ucaId } = data;
  if (!ucaId) {
    getAuthApiData.then((authInfo) => {
      const userId = authInfo.ucaId;
      body = {
        ...body,
        data: {
          ...body.data,
          ucaId: userId,
        },
      };
      axios.post(`${getLambdaHost()}/uca-logger/v1/log`, body);
    });
  } else {
    axios.post(`${getLambdaHost()}/uca-logger/v1/log`, body);
  }
};

/**
 * Get user basic profile information
 * @param {success callback function} setBasicProfileData
 */
export const getBasicProfileInfo = (setBasicProfileData) => {
  Auth.currentAuthenticatedUser({})
    .then((user) => {
      setBasicProfileData({
        ...user?.attributes,
      });
    })
    .catch(() => {
      setBasicProfileData(null);
    });
};

/**
 * Verify User Phone attribute
 * This sends the SMS code to user phone number
 * @param {success callback} onSuccess
 * @param {failure callback} onFailure
 */
export const verifyPhoneAttribute = (onSuccess, onFailure, ucaId) => {
  Auth.verifyCurrentUserAttribute('phone_number')
    .then(() => {
      sendLog(
        {
          ucaId,
          code: 'MFA_220',
          message: 'Successfully sent SMS Optin verification code',
          fn: 'verifyPhoneAttribute',
        },
        'MFA',
        'info'
      );
      onSuccess();
    })
    .catch((e) => {
      sendLog(
        {
          ucaId,
          code: 'MFA_420',
          message: 'Failed to send SMS Optin verification code',
          fn: 'verifyPhoneAttribute',
          error: e,
        },
        'MFA',
        'info'
      );
      onFailure(e);
    });
};

/**
 * 1. Update the phone number to basic profile
 * 2. Send verify code to phone number
 *
 * @param {phone number string} phoneNumber
 * @param {success callback} onSuccess
 * @param {failure callback} onFailure
 */
export const updateProfilePhoneNumber = (phoneNumber, onSuccess, onFailure) => {
  getAuthApiData()
    .then((authInfo) => {
      const {
        fastlyHost, ucaId, headers, user,
      } = authInfo;

      axios
        .post(
          `${fastlyHost}/accounts/v2/profiles/${ucaId}/update-basic-attributes`,
          { phoneNumber },
          { headers }
        )
        .then(() => {
          user.getUserData(
            () => {
              verifyPhoneAttribute(onSuccess, onFailure, ucaId);
            },
            { bypassCache: true }
          );
        })
        .catch(() => {
          onFailure();
        });
    })
    .catch(() => {
      onFailure();
    });
};

/**
 *
 * @param {MFA code received by the user} confirmationCode
 * @param {success callback} onSuccess
 * @param {failure callback} onFailure
 * @param {CognitoUser} user
 * @param {Object} toggles
 */
const submitSMSMFACode = (
  confirmationCode,
  onSuccess,
  onFailure,
  postLogin,
  user,
  toggles
) => {
  const {
    DISABLE_ATG_LOGIN,
    UCA_PROFILE_COOKIE,
    PZP_IDENTITY,
    EMPLOYEE_STATUS_V2,
    DISCOUNT_ELIGIBILITY_V1,
  } = toggles;

  Auth.confirmSignIn(user, confirmationCode, 'SMS_MFA')
    .then(() => Auth.currentAuthenticatedUser({}))
    .then((loggedUser) => {
      const ucaId = getUcaId(loggedUser);
      sendLog(
        {
          ucaId,
          code: 'MFA_223',
          message: 'Successfully submitted SMS MFA code',
          fn: 'submitSMSMFACode',
        },
        'MFA',
        'info'
      );
      obtainAtgSession(loggedUser, false, false, DISABLE_ATG_LOGIN)
        .then(() => {
          knownCustomerCookie(loggedUser, PZP_IDENTITY);

          if (UCA_PROFILE_COOKIE) {
            setUcaProfileCookie(
              loggedUser,
              onSuccess,
              EMPLOYEE_STATUS_V2,
              DISCOUNT_ELIGIBILITY_V1
            );
          } else {
            onSuccess(loggedUser);
          }
          if (postLogin) postLogin(loggedUser);
        })
        .catch(() => {
          onFailure();
        });
    })
    .catch((e) => {
      onFailure();
      // TODO: When confirm sign in fails, need to check with Iurii if the user can resubmit the request
      // if resubmit is possible, then need to resarch if we can call the same method confirmSignIn or alternate approaches
      sendLog(
        {
          ucaId: getUcaId(user),
          code: 'MFA_423',
          message: 'Error submitting SMS MFA code',
          error: e,
          fn: 'submitSMSMFACode',
        },
        'MFA',
        'error'
      );
    });
};

/**
 * 1. Submits the phone verification code entered by the user
 * 2. On Successful phone verification, sets the preferred MFA method to SMS
 * 3. When code verfication fails, throws 'Error verifying the confirmation code'
 * 4. When code verification succeeeds but set MFA fails, throws 'User verified successfully but unable to set up MFA'
 *
 * @param {confirmation code received by the user} confirmationCode
 * @param {success callback} onSuccess
 * @param {failure callback} onFailure
 * @param {CognitoUser} user
 */
const verifyPhoneAttributeSubmit = (
  confirmationCode,
  onSuccess,
  onFailure,
  user,
  ucaId,
  fastlyHost,
  headers,
  toggles
) => {
  Auth.verifyCurrentUserAttributeSubmit('phone_number', confirmationCode)
    .then(() => {
      sendLog(
        {
          ucaId,
          code: 'MFA_221',
          message: 'Successfully submitted Optin SMS verification code',
          fn: 'verifyPhoneAttributeSubmit',
        },
        'MFA',
        'info'
      );

      if (toggles?.USE_MFA_API) {
        axios
          .post(
            `${fastlyHost}/manage-accounts/v1/enable-sms-mfa/${ucaId}`,
            { ucaId },
            { headers }
          )
          .then(() => {
            sendLog(
              {
                ucaId,
                code: 'MFA_222',
                message: 'Successfully set SMS as preferred MFA',
                fn: 'verifyPhoneAttributeSubmit',
              },
              'MFA',
              'info'
            );
            onSuccess();
          })
          .catch((e) => {
            sendLog(
              {
                ucaId,
                code: 'MFA_422',
                message: 'Error setting SMS as preferred MFA',
                error: e,
                fn: 'verifyPhoneAttributeSubmit',
              },
              'MFA',
              'error'
            );
            onFailure('User verified successfully but unable to set up MFA');
          });
      } else {
        Auth.setPreferredMFA(user, 'SMS')
          .then(() => {
            sendLog(
              {
                ucaId,
                code: 'MFA_222',
                message: 'Successfully set SMS as preferred MFA',
                fn: 'verifyPhoneAttributeSubmit',
              },
              'MFA',
              'info'
            );
            onSuccess();
          })
          .catch((e) => {
            sendLog(
              {
                ucaId,
                code: 'MFA_422',
                message: 'Error setting SMS as preferred MFA',
                error: e,
                fn: 'verifyPhoneAttributeSubmit',
              },
              'MFA',
              'error'
            );
            // TODO: call a different failure function
            onFailure('User verified successfully but unable to set up MFA');
          });
      }
    })
    .catch((e) => {
      sendLog(
        {
          ucaId,
          code: 'MFA_421',
          message: 'Error verifying Optin SMS verification code',
          error: e,
          fn: 'verifyPhoneAttributeSubmit',
        },
        'MFA',
        'error'
      );
      onFailure('Error verifying the confirmation code');
    });
};

/**
 *
 * 1. Verify the SMS confirmation code entered by the user
 * 2. Depending on the mfaContext, it either verifies the phone number
 *    or verifies the SMS MFA challenge
 * 3. Throws 'Unexpected MFA flow error' if flow type is neither Optin or Signin
 *
 * @param {*} confirmationCode
 * @param {*} onSuccess
 * @param {*} onFailure
 * @param {*} mfaContext
 * @param {*} toggles
 * @param {*} unauthorizedUser
 */
export const verifySMS = (
  confirmationCode,
  onSuccess,
  onFailure,
  mfaContext,
  toggles,
  unauthorizedUser
) => {
  const { flow } = mfaContext;
  if (unauthorizedUser && (flow === 'Signin' || flow === 'CartSignin')) {
    submitSMSMFACode(
      confirmationCode,
      onSuccess,
      onFailure,
      mfaContext?.postLogin,
      unauthorizedUser,
      toggles
    );

    return;
  }

  if (flow === 'Optin' || flow === 'Register') {
    getAuthApiData()
      .then((authInfo) => {
        const {
          user, ucaId, fastlyHost, headers,
        } = authInfo;

        verifyPhoneAttributeSubmit(
          confirmationCode,
          onSuccess,
          onFailure,
          user,
          ucaId,
          fastlyHost,
          headers,
          toggles
        );
      })
      .catch(() => onFailure());
  } else {
    onFailure('Unexpected MFA flow');
  }
};

/**
 * Reset user phone and MFA flow
 *
 * @param {string} email
 * @param {function} successCallback
 * @param {function} errCallback
 */
export const resetUserPhoneMFA = (
  email,
  successCallback = () => {},
  errCallback = () => {}
) => {
  const fastlyHost = getFastlyHost();

  axios
    .post(`${fastlyHost}/manage-accounts/v1/init-phone-reset`, { email })
    .then(() => {
      successCallback();
    })
    .catch((err) => {
      errCallback(err);
    });
};

/**
 * Sets expiration date for disabling opt in user notification(30 days)
 * When user clicked 'Cancel' first 3 times we dont put opt in flow on hold for 30 days
 * @param {Object} userAttributes
 */
export const updateOptInExpiration = (userAttributes) => {
  const { email } = userAttributes || {};

  if (!email) {
    return;
  }

  const optInRefuses = JSON.parse(
    window.localStorage.getItem(`${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`)
  );
  const { refusesCount = 0 } = optInRefuses || {};

  if (refusesCount === 2) {
    const expDate = getTime(addDays(new Date(), 30));

    saveToLocalStorage(
      `${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`,
      JSON.stringify({
        refusesCount: refusesCount + 1,
        expDate: getTime(expDate),
      })
    );

    return;
  }

  saveToLocalStorage(
    `${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`,
    JSON.stringify({
      refusesCount: refusesCount + 1,
    })
  );
};

/**
 * The function check if we should show user MFA opt in verification again
 * We should show user MFA opt in verification once per 30 days
 * In case if count of refuses from phone verification < 3 or expiration date is unset
 * We return also true and consider that. We also check if user phone is verified
 * @param {CognitoUser | {}} user
 * @return {boolean}
 */
export const checkIfMFAOptInAvailable = (user = {}) => {
  const { phone_number_verified, email } = user.attributes || {};

  // If phone number has been already verified we don't need to go through MFA flow
  if (phone_number_verified || !email) {
    return false;
  }

  const optInRefuses = JSON.parse(
    window.localStorage.getItem(`${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`)
  );
  const { refusesCount = 0, expDate } = optInRefuses || {};

  if (!expDate || refusesCount < 3) {
    return true;
  }

  return isAfter(new Date(), new Date(expDate));
};

/**
 * Reset opt in local storage parameters when disable opt is expired
 * @param {CognitoUser | {}} user
 */
export const resetMFAOptinAvailability = (user = {}) => {
  const { email } = user.attributes || {};

  if (!email) {
    return;
  }

  const optInRefuses = JSON.parse(
    window.localStorage.getItem(`${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`)
  );
  const { expDate } = optInRefuses || {};

  if (!expDate || isBefore(new Date(), new Date(expDate))) {
    return;
  }

  window.localStorage.removeItem(
    `${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`
  );
};

/**
 * Removes opt in availability expiration dates from local storage
 * @param {Object} userAttributes
 */
export const removeOptInExpirationFromStorage = (userAttributes) => {
  const { email } = userAttributes;

  if (!email) {
    return;
  }

  window.localStorage.removeItem(
    `${LOCAL_STORAGE_MFA_OPT_IN_REFUSES}.${email}`
  );
};
