import React, { useEffect } from 'react';
import MFAModalContainer from 'client/common/components/VerifyAccountMFA/MFAModalContainer';
import VerifySMSCode from 'client/common/components/VerifyAccountMFA/VerifySMSCode';
import { updateNameHeaders } from 'client/common/components/YourNeimans/components/utilities';
import { mfaFlows } from 'client/common/components/VerifyAccountMFA/constants';
import 'client/common/components/VerifyAccountMFA/MFAComponentStyles.scss';
import './AccountAccess.scss';

const LetsConfirm = ({
  toggles, user, redirect, isVerify,
}) => {
  const userAttributes = {};
  if (user) {
    // If user attributes are missed, it can be only sign in flow
    let email = null;
    if (user?.extra) {
      email = user?.extra?.email;
    }
    if (!email) {
      const emailRegExp = new RegExp(`${user?.keyPrefix || ' '}.|.userData`, 'g');
      email = user?.userDataKey && user?.keyPrefix ? user.userDataKey?.replace(emailRegExp, '') : '';
    }
    userAttributes.email = email;
    userAttributes.phone_number = user?.challengeParam?.CODE_DELIVERY_DESTINATION || '';
  }

  useEffect(() => {
    document.title = 'Let\'s Confirm it\'s you';
  }, []);

  const placeHolderFunc = () => {};

  const gotoHelpStep = () => {
    window?.YourNeimansMFA.toggleMFA(
      placeHolderFunc,
      { flow: mfaFlows.needHelp, mfaUser: user, toggles }
    );
  };

  const goToLoginPage = () => {
    if (isVerify) {
      window.location.href = '/login/verify';
    } else {
      window.location.href = redirect === '/order-history' ? '/guest-order-history' : '/login';
    }
  };

  const finalizeLogin = () => {
    if (redirect) {
      window.location.href = redirect;
    } else {
      window.location.href = '/my/account/overview';
    }
  };


  return (
    <div className="mfa-otp">
      <div className="form">
        <div className="form-content">
          <div className="sign-in-content">
            <div className="mfaModalTitle">Let&apos;s Confirm it&apos;s you</div>
            <VerifySMSCode
              verifypage
              increaseModalStep={placeHolderFunc}
              userAttributes={userAttributes}
              mfaContext={{ flow: 'Signin' }}
              navigateToHelpPage={gotoHelpStep}
              callVerifyPhoneOnLoad={() => {}}
              toggles={toggles}
              closeModal={finalizeLogin}
              goToLoginPage={goToLoginPage}
              user={user}
              updateNameHeaders={updateNameHeaders}
            />
            <MFAModalContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetsConfirm;
