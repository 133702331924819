import window from 'window-or-global';
import axios from 'axios';
import httpWithLogging from 'universal/http-client';
import { getAuthApiData } from '../../../utilities/amplifyUtils';

export const getToggles = () => {
  const config = window && window.AccountAccessConfig;
  if (config) {
    const {
      DT_LOGIN_FLOW = false,
    } = config;

    return {
      DT_LOGIN_FLOW,
    };
  }

  return {};
};

export const getNMVIPCheckSpendAmount = async () => {
  try {
    const { ucaId, headers, lambdaHost } = await getAuthApiData();

    const { data = {} } = await axios.get(
      `${lambdaHost}/osp-cc-customers/v1/getAnnualSpentByUcaId/${ucaId}/NM`,
      { headers }
    );

    return data?.found ? data : 0;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return 0;
  }
};
export const getBillingAddressPrefill = async (state) => {
  try {
    const { fastlyHost, user } = await getAuthApiData();
    const body = {
      firstName: user?.attributes?.given_name, //eslint-disable-line
      lastName: user?.attributes?.family_name, //eslint-disable-line
      emailAddress: user?.attributes?.email,
      brand: 'NM',
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    const requestApi = httpWithLogging(state, 5000, true);
    const {
      data = {},
    } = await requestApi.post(`${fastlyHost}/oco-plcc-apply/get-token`, body, {
      headers,
    });

    if (data?.orchestratorCacheKey) {
      const rlink = window.location.hostname;
      const newLocation = `${NMConfig.CAPITALONE_DOMAIN}/?brandCode=NEIMANMARCUSPLCC&media=NMHPFOOTERCREDITCARDOPENACCOUNT&marketingChannelCode=WEB&orchestratorCacheKey=${data?.orchestratorCacheKey}&rLink=${rlink}`;
      window.location.href = newLocation;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
